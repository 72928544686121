import { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Use a ternary operator to make sure that the document object is defined
const portalRoot =
  typeof document !== `undefined` ? document.getElementById('portal') : null;

interface PortalProps {
  className?: string;
}

const Portal: FunctionComponent<PortalProps> = ({ children, className }) => {
  const el =
    typeof document !== `undefined` ? document.createElement('div') : null;

  useEffect(() => {
    if (el) {
      // Default classes
      const classList = ['fixed', 'w-full', 'top-0', 'z-50'];
      // If className prop is present add each class the classList
      if (className) {
        className.split(' ').forEach((item) => classList.push(item));
      }
      classList.forEach((item) => portalRoot?.classList.add(item));

      portalRoot?.appendChild(el);
    }
    return () => {
      if (el) {
        portalRoot?.removeChild(el);
      }
    };
  });

  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
};

export default Portal;
