import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import Loader from '../icons/loader';
import { loginAutoUserAction } from '@/store/actions/auth-actions';

interface AccountProps extends RouteComponentProps {}

const AutoLoginPage: FunctionComponent<AccountProps> = () => {
  const dispatch = useDispatch();

  dispatch(loginAutoUserAction());

  return (
    <div className="container dark:text-textlightblue my-8 w-full text-center flex flex-col align-middle items-center">
      <h1 className="heading2 mb-8">Login</h1>
      <Loader size="lg" />
      <p className="mt-8">Try to login, wait for it...</p>
    </div>
  );
};

export default AutoLoginPage;
