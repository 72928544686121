import React, { FunctionComponent } from 'react';
import { Router } from '@reach/router';
import Layout from '@/components/layout';
import SEO from '@/components/seo';
import { graphql } from 'gatsby';
import { Global, PageContext, SiteMetadata } from '@/utils/types';
import AccountPage from '@/components/app/account';
import LoginPage from '@/components/app/login';
import AdminPage from '@/components/app/admin';
import LogoutPage from '@/components/app/logout';
import PrivateRoute from '@/components/private-route';
import RegisterPage from '@/components/app/register';
import ArticlePreviewPage from '@/components/app/article-preview';
import ForgotPasswordPage from '@/components/app/forgot-password';
import ResetPasswordPage from '@/components/app/reset-password';
import { useSelector } from 'react-redux';
import { State } from '@/store/store';
import { Meta } from '@/models/meta';

interface AppProps {
  data: {
    strapiGlobal: Global;
    site: SiteMetadata;
  };
}

const App: FunctionComponent<AppProps> = ({ data }) => {
  const {
    strapiGlobal,
    site: {
      siteMetadata: {
        languages: { locales, defaultLocale },
        ...siteMetadata
      },
    },
  } = data;

  const pageContext: PageContext = {
    id: 'account',
    locale: defaultLocale,
    locales,
    defaultLocale,
    slug: 'account',
    ...siteMetadata,
  };

  const meta = useSelector<State, Meta>((state) => state.meta);

  return (
    <>
      <SEO
        seo={{
          metaTitle: meta.title,
        }}
        global={strapiGlobal}
        pageContext={pageContext}
      />

      <Layout pageContext={pageContext} global={strapiGlobal}>
        <Router basepath="/app">
          <PrivateRoute path="/account" component={AccountPage} />
          <PrivateRoute path="/admin" component={AdminPage} forAdmin={true} />
          <PrivateRoute
            path="/article/:locale/:slug"
            component={ArticlePreviewPage}
            forAdmin={true}
          />
          <LoginPage path="/login" />
          <ForgotPasswordPage path="/forgot-password" />
          <ResetPasswordPage path="/reset-password/:token" />
          <RegisterPage path="/register" />
          <LogoutPage path="/logout" />
          <LoginPage path="/" />
        </Router>
      </Layout>
    </>
  );
};

export default App;

export const query = graphql`
  query AppQuery {
    site {
      siteMetadata {
        languages {
          locales
          defaultLocale
        }
        author {
          name
        }
        organization {
          logo
          name
          url
        }
        siteUrl
        image
        title
        description
      }
    }
    strapiGlobal {
      ...GlobalData
    }
  }
`;
