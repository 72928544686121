import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@/store/store';
import { RouteComponentProps } from '@reach/router';
import { getArticlesAction } from '@/store/actions/articles-actions';
import ArticleCard from '../elements/article-card';
import { Article } from '@/models/articles';
import Select from '@/components/elements/forms/select';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ArticleCardSke from '../elements/article-card-skeleton';
import MetaHeader from './meta';

interface AdminProps extends RouteComponentProps {}

const AdminPage: FunctionComponent<AdminProps> = () => {
  const meta = {
    title: 'Admin dashboard page',
  };

  const articles = useSelector<State, Article[]>(
    (state) => state.articles.articles,
  );
  const error = useSelector<State, string | undefined>(
    (state) => state.articles.error,
  );
  const loading = useSelector<State, boolean>(
    (state) => state.articles.loading,
  );
  const dispatch = useDispatch();

  const defaultLocale = 'fr';
  const locales = ['en', 'fr'];

  useEffect(() => {
    dispatch(getArticlesAction(defaultLocale));
  }, []);

  const validationSchema = yup.object({
    locale: yup.string().required('Language is required'),
  });

  const formik = useFormik({
    initialValues: {
      locale: defaultLocale,
    },
    validationSchema: validationSchema,
    onSubmit: async ({ locale }, { setSubmitting }) => {
      dispatch(getArticlesAction(locale));
      setSubmitting(false);
    },
  });

  return (
    <MetaHeader meta={meta}>
      <div className="container dark:text-textlightblue my-8">
        <h1 className="heading2 mb-4">Admin page</h1>
        <p className="pb-4">To preview draft articles dynamically</p>
        <h2 className="heading3"> Last articles </h2>
        <form onSubmit={formik.handleSubmit}>
          <Select
            id="locale"
            name="locale"
            label="Languages"
            options={locales}
            value={formik.values.locale}
            onChange={(e: any) => {
              formik.handleChange(e);
              formik.handleSubmit();
            }}
            error={formik.touched.locale && Boolean(formik.errors.locale)}
            helperText={formik.touched.locale && formik.errors.locale}
          />
        </form>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {!loading
            ? articles.map((article) => {
                return (
                  <ArticleCard
                    article={article}
                    key={`article__${article.slug}`}
                  />
                );
              })
            : Array.from({ length: 3 }, (v, k) => k).map((i) => {
                return <ArticleCardSke key={`article_ske_${i}`} />;
              })}
        </div>
      </div>
    </MetaHeader>
  );
};

export default AdminPage;
