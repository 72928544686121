import classNames from 'classnames';
import React, { FunctionComponent, useState } from 'react';
import Portal from './portal';

export enum AlertLevel {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export interface AlertType {
  visible: boolean;
  message?: string;
  level: AlertLevel;
  delayMs: number;
}

export const useAlert = (
  level: AlertLevel = AlertLevel.ERROR,
  delayMs: number = 3000,
) => {
  const [alert, setAlert] = useState<AlertType>({
    level,
    visible: false,
    message: undefined,
    delayMs,
  });

  const triggerAlert = (message: string, level?: AlertLevel) => {
    const newAlert: AlertType = {
      ...alert,
      visible: true,
      message,
    };
    if (level) {
      newAlert.level = level;
    }
    setAlert(newAlert);

    setTimeout(() => {
      setAlert({
        ...alert,
        visible: false,
        message: undefined,
      });
    }, alert.delayMs);
  };

  return { alert, triggerAlert };
};

interface AlertProps {
  alert: AlertType;
}

const Alert: FunctionComponent<AlertProps> = ({ alert, children }) => {
  return (
    <>
      {alert.visible && (
        <Portal>
          <div
            className={classNames(
              'text-center py-2 text-white w-full z-30 h-10 transition duration-500 text',
              {
                'bg-secondary text-darkblue': alert.level === AlertLevel.INFO,
                'bg-third text-white': alert.level === AlertLevel.ERROR,
              },
            )}
          >
            {alert.message ?? children}
          </div>
        </Portal>
      )}
    </>
  );
};

export default Alert;
