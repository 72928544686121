import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMetaAction } from '@/store/actions/meta-actions';
import { Meta } from '@/models/meta';

interface MetaHeaderProps {
  meta: Meta;
}

const MetaHeader: FunctionComponent<MetaHeaderProps> = ({ meta, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMetaAction(meta));
  }, []);

  return <>{children}</>;
};

export default MetaHeader;
