import React, { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '@/store/store';
import { Link, navigate } from 'gatsby';
import { User } from '@/utils/types';
import TextField from '@/components/elements/forms/textfield';
import Button from '@/components/elements/button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { loginUserAction } from '@/store/actions/auth-actions';
import { useLocalStorage } from '@/hooks/use-storage';
import { RouteComponentProps } from '@reach/router';
import Alert, { useAlert } from '@/components/elements/alert';
import { AuthError, getAuthErrorMessage } from '@/models/errors';
import { AuthState } from '@/store/reducers/auth-reducer';
import MetaHeader from './meta';

interface LoginPageProps extends RouteComponentProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const { alert, triggerAlert } = useAlert();

  const { user, error, loading } = useSelector<State, AuthState>(
    (state) => state.auth,
  );

  const dispatch = useDispatch();

  const meta = {
    title: 'Login page',
  };

  const [_, setLsToken, removeLsToken] = useLocalStorage<string | undefined>(
    'token',
    undefined,
  );

  const postLogin = (user: User) => {
    if (user.token) {
      removeLsToken();
      setLsToken(user.token);
    }
    navigate('/app/account');
  };

  useEffect(() => {
    if (user) {
      postLogin(user);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      triggerAlert(getAuthErrorMessage(error as AuthError));
    }
  }, [error]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email, password }, { setSubmitting }) => {
      dispatch(loginUserAction({ email, password }));
      setSubmitting(false);
    },
  });

  return (
    <MetaHeader meta={meta}>
      <Alert alert={alert} />
      <div className="container dark:text-textlightblue my-8">
        <div className="flex flex-col items-center">
          <h1 className="heading2 mb-4">Login</h1>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              placeholder=""
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button
              type="submit"
              appearance="dark"
              compact={true}
              button={{
                id: '0',
                text: 'Login',
                type: 'primary',
                url: '',
              }}
              disabled={formik.isSubmitting}
              loading={loading}
            />
            <Link to="/app/forgot-password" className="text-primary ml-4">
              Forgot password?
            </Link>
          </form>
          <p className="pt-4">
            Don't have account?{' '}
            <Link to="/app/register" className="text-primary">
              register here
            </Link>
          </p>
        </div>
      </div>
    </MetaHeader>
  );
};

export default LoginPage;
