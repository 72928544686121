import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface ArticleSkeProps {}

const ArticleSkeComponent: FunctionComponent<ArticleSkeProps> = ({}) => {
  const textGrid = [
    { id: 1, size: 'col-span-3' },
    { id: 2, size: 'col-span-1' },
    { id: 3, size: 'col-span-2' },
    { id: 4, size: 'col-span-2' },
    { id: 5, size: 'col-span-1' },
    { id: 6, size: 'col-span-3' },
    { id: 7, size: 'col-span-1' },
    { id: 8, size: 'col-span-1' },
    { id: 9, size: 'col-span-1' },
    { id: 10, size: 'col-span-1' },
    { id: 11, size: 'col-span-3' },
    { id: 12, size: 'col-span-1' },
    { id: 13, size: 'col-span-2' },
    { id: 14, size: 'col-span-2' },
    { id: 15, size: 'col-span-1' },
    { id: 16, size: 'col-span-3' },
    { id: 17, size: 'col-span-1' },
    { id: 18, size: 'col-span-1' },
    { id: 19, size: 'col-span-1' },
    { id: 20, size: 'col-span-1' },
    { id: 21, size: 'col-span-1' },
    { id: 22, size: 'col-span-1' },
  ];

  return (
    <>
      <div className="flex flex-col animate-pulse">
        <div className="w-full h-400px bg-gray-300"></div>

        <div className="container flex flex-col items-center">
          <h1
            className="dark:text-white text-center heading1 my-4 w-64 bg-gray-300 h-8 rounded-md
          "
          ></h1>
          <h2 className="dark:text-textlightblue text-center heading2 w-200px bg-gray-300 h-8 rounded-md mb-6"></h2>

          <div className="grid grid-cols-4 gap-1 w-full">
            {textGrid.map(({ id, size }) => (
              <p
                id="description"
                key={id}
                className={classNames(
                  size,
                  'w-25 h-4 bg-gray-300 mb-2 rounded-md',
                )}
              ></p>
            ))}
          </div>

          <hr className="my-4" />

          <div className="flex justify-start align-middle gap-2 w-full">
            <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
            <div>
              <p className="font-medium w-24 h-4 bg-gray-300 mb-2 rounded-md flex-none"></p>
              <p className="font-medium w-16 h-4 bg-gray-300 mb-2 rounded-md flex-none"></p>
            </div>
            <div className="flex-1" />
            {/* <LoveButton slug={article.slug} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleSkeComponent;
