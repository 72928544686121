import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface CardProps {}

const ArticleCardSke: FunctionComponent<CardProps> = () => {
  const textGrid = [
    { id: 1, size: 'col-span-3' },
    { id: 2, size: 'col-span-1' },
    { id: 3, size: 'col-span-2' },
    { id: 4, size: 'col-span-2' },
    { id: 5, size: 'col-span-1' },
    { id: 6, size: 'col-span-3' },
    { id: 7, size: 'col-span-1' },
    { id: 8, size: 'col-span-1' },
    { id: 9, size: 'col-span-1' },
  ];
  return (
    <>
      <div className="animate-pulse w-full p-6 flex flex-col flex-grow flex-shrink">
        <div className="flex-1 bg-white dark:bg-deepblue rounded-xl overflow-hidden shadow-lg  ">
          <div className="w-full h-32 bg-gray-300"></div>
          <div className="p-4">
            <div className="flex flex-row gap-2">
              <p
                id="category"
                className="uppercase text-xs w-20 h-4 bg-gray-300 mb-2 rounded-md"
              ></p>
            </div>

            <p
              id="title"
              className="heading3 w-25 h-6 bg-gray-400 mb-2 rounded-md"
            ></p>
            <div className="grid grid-cols-4 gap-1">
              {textGrid.map(({ id, size }) => (
                <p
                  id="description"
                  key={id}
                  className={classNames(
                    size,
                    'w-25 h-4 bg-gray-300 mb-2 rounded',
                  )}
                ></p>
              ))}
            </div>

            <div>
              <hr className="my-2" />
              <div className="flex justify-start align-center content-center items-center gap-2">
                <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
                <p className="font-medium w-24 h-4 bg-gray-300 mb-2 rounded-md flex-none"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCardSke;
