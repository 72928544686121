import React, { FunctionComponent } from 'react';
import { User } from '@/utils/types';
import { useSelector } from 'react-redux';
import { State } from '@/store/store';
import { RouteComponentProps } from '@reach/router';
import MetaHeader from './meta';

interface AccountProps extends RouteComponentProps {}

const AccountPage: FunctionComponent<AccountProps> = () => {
  const meta = {
    title: 'Account page',
  };

  const user = useSelector<State, User | undefined>((state) => state.auth.user);

  return (
    <MetaHeader meta={meta}>
      <div className="container dark:text-textlightblue my-8">
        <h1 className="heading2 mb-4">Account</h1>
        <p>user logged: {user?.email}</p>
      </div>
    </MetaHeader>
  );
};

export default AccountPage;
