import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '@/store/store';

import { navigate } from 'gatsby';
import { User } from '@/utils/types';
import Button from '@/components/elements/button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextField from '@/components/elements/forms/textfield';
import { registerUserAction } from '@/store/actions/auth-actions';
import { useLocalStorage } from '@/hooks/use-storage';
import { RouteComponentProps } from '@reach/router';
import { AuthError, getAuthErrorMessage } from '@/models/errors';
import Alert, { AlertType, useAlert } from '@/components/elements/alert';
import { AuthState } from '@/store/reducers/auth-reducer';
import MetaHeader from './meta';

interface RegisterPageProps extends RouteComponentProps {}

const RegisterPage: FunctionComponent<RegisterPageProps> = () => {
  const meta = {
    title: 'Registration page',
  };

  const { user, error, loading } = useSelector<State, AuthState>(
    (state) => state.auth,
  );

  const { alert, triggerAlert } = useAlert();

  const dispatch = useDispatch();

  const [_, setLsToken, removeLsToken] = useLocalStorage<string | undefined>(
    'token',
    undefined,
  );

  const postLogin = (user: User) => {
    if (user.token) {
      removeLsToken();
      setLsToken(user.token);
    }
    navigate('/app/account');
  };

  useEffect(() => {
    if (user) {
      postLogin(user);
    }
  }, [user]);

  useEffect(() => {
    if (error) {
      triggerAlert(getAuthErrorMessage(error as AuthError));
    }
  }, [error]);

  const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (
      { username, email, password },
      { setSubmitting, setErrors },
    ) => {
      setErrors({ email: undefined });
      dispatch(registerUserAction({ username, email, password }));

      setSubmitting(false);
    },
  });

  return (
    <MetaHeader meta={meta}>
      <Alert alert={alert} />
      <div className="container dark:text-textlightblue my-8">
        <div className="flex flex-col items-center">
          <h1 className="heading2 mb-4">Registration</h1>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              placeholder=""
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              placeholder=""
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              id="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirm Password"
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
            <Button
              type="submit"
              appearance="dark"
              compact={true}
              button={{
                id: '0',
                text: 'Register',
                type: 'primary',
                url: '',
              }}
              disabled={formik.isSubmitting}
              loading={loading}
            />
          </form>
          <p className="pt-4">
            Already have account?{' '}
            <Link to="/app/login" className="text-primary">
              Login here
            </Link>
          </p>
        </div>
      </div>
    </MetaHeader>
  );
};

export default RegisterPage;
