import React, { FunctionComponent, useEffect } from 'react';
import { navigate } from 'gatsby';
import { User } from '@/utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@/store/store';
import { logoutUserAction } from '@/store/actions/auth-actions';
import { RouteComponentProps } from '@reach/router';
import { useLocalStorage } from '@/hooks/use-storage';
import Loader from '../icons/loader';

interface LogoutProps extends RouteComponentProps {}

const LogoutPage: FunctionComponent<LogoutProps> = () => {
  const isLoggedIn = useSelector<State, boolean>(
    (state) => state.auth.isLoggedIn,
  );
  const dispatch = useDispatch();

  const [lsToken, _setLsToken, removeLsToken] = useLocalStorage<
    string | undefined
  >('token', undefined);
  if (lsToken) {
    removeLsToken();
  }
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(logoutUserAction());
    }
    navigate('/app/login');
  }, [isLoggedIn]);

  return (
    <div className="container dark:text-textlightblue my-8 w-full text-center flex flex-col align-middle items-center">
      <h1 className="heading2 mb-8">Logout</h1>
      <Loader size="lg" />
      <p>You will be logged out shortely</p>
    </div>
  );
};

export default LogoutPage;
