export enum AuthError {
  UserAlreadyExists = 'UserAlreadyExists',
  FieldsRequired = 'FieldsRequired',
  UserDoesntExist = 'UserDoesntExist',
  WrongPassword = 'WrongPassword',
  Unknown = 'UnknownError',
}

export const getAuthErrorMessage = (error: AuthError): string => {
  switch (error) {
    case AuthError.UserDoesntExist:
      return "User e-mail doesn't exist";
    case AuthError.UserAlreadyExists:
      return 'User already exists, try another e-mail';
    case AuthError.FieldsRequired:
      return 'Some required fields are missing';
    case AuthError.WrongPassword:
      return 'Wrong password, try again';
    default:
      return 'Unknown error, try agin';
  }
};
