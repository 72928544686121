import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { textfieldSize } from '@/styles/textfield.module.css';

interface SelectProps {
  id: string;
  name: string;
  label?: string;
  value: any;
  onChange: any;
  error?: boolean;
  helperText?: string | false;
  options: string[];
}

const Select: FunctionComponent<SelectProps> = ({
  id,
  name,
  label,
  options,
  value,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
      {label && (
        <label htmlFor={name} className="text-base leading-7">
          {label}
        </label>
      )}
      <div className="relative">
        <select
          className="block appearance-none w-full border bg-none px-4 py-2 mt-2 mr-4 text-base text-black dark:text-white transition duration-500 ease-in-out transform rounded-lg bg-gray-100 dark:bg-deepblue focus:border-gray-100 focus:bg-white  dark:focus:bg-deepblue dark:focus:border-deepblue focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current  ring-primary"
          id={id}
          onChange={onChange}
          value={value}
        >
          {options.map((option) => (
            <option key={option} value={option} label={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black dark:text-white">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {error && (
        <p className="text-red-500 h-8 text-sm mt-1 ml-2 text-left w-150">
          {helperText ?? 'unknown'}
        </p>
      )}
      {!error && <p className="h-8"></p>}
    </div>
  );
};

export default Select;
