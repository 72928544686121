import React, { FunctionComponent } from 'react';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { State } from '@/store/store';
import { useSelector } from 'react-redux';
import AutoLoginPage from './app/auto-login';
import { useLocalStorage } from '@/hooks/use-storage';
import { AuthState } from '@/store/reducers/auth-reducer';
import UnauthorizedPage from './app/unauthorized';

interface PrivateRouteProps extends RouteComponentProps {
  component: FunctionComponent<any>;
  forAdmin?: boolean;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  location,
  forAdmin = false,
  ...rest
}) => {
  const isLoggedIn = useSelector<State, boolean>(
    (state) => state.auth.isLoggedIn,
  );

  const isAdmin = useSelector<State, boolean>((state) => state.auth.isAdmin);

  const [token] = useLocalStorage<string | undefined>('token', undefined);

  if (isLoggedIn) {
    if (forAdmin === true) {
      return isAdmin ? <Component {...rest} /> : <UnauthorizedPage />;
    } else {
      return <Component {...rest} />;
    }
  } else if (token !== undefined) {
    return <AutoLoginPage />;
  } else {
    navigate('/app/login');
    return null;
  }
};
export default PrivateRoute;
