import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { textfieldSize } from '@/styles/textfield.module.css';

interface TextfieldProps {
  id: string;
  name: string;
  placeholder?: string;
  type?: string;
  label?: string;
  fullWidth: boolean;
  value: any;
  onChange: any;
  error?: boolean;
  helperText?: string | false;
}

const Textfield: FunctionComponent<TextfieldProps> = ({
  id,
  name,
  type,
  label,
  placeholder,
  fullWidth,
  value,
  onChange,
  error,
  helperText,
}) => {
  return (
    <div className={classnames('relative pt-4', textfieldSize)}>
      {label && (
        <label htmlFor={name} className="text-base leading-7">
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        className="w-full px-4 py-2 mt-2 mr-4 text-base text-black dark:text-white transition duration-500 ease-in-out transform rounded-lg bg-gray-100 dark:bg-deepblue focus:border-blueGray-500 focus:bg-white  dark:focus:bg-deepblue dark:focus:border-blueGray-500 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current  ring-primary"
        onChange={onChange}
        value={value}
      />
      {error && (
        <p className="text-red-500 h-8 text-sm mt-1 ml-2 text-left w-150">
          {helperText ?? 'unknown'}
        </p>
      )}
      {!error && <p className="h-8"></p>}
    </div>
  );
};

export default Textfield;
