import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import ArticleComponent from '../elements/article';
import { getArticle } from '@/services/articles-service';
import { Article } from '@/models/articles';
import ArticleSkeComponent from '../elements/article-skeleton';
import { Meta } from '@/models/meta';
import MetaHeader from './meta';

interface ArticlePreviewProps extends RouteComponentProps {
  slug: string;
  locale: string;
}

const ArticlePreviewPage: FunctionComponent<ArticlePreviewProps> = ({
  slug,
  locale,
}) => {
  const [article, setArticle] = useState<Article | null>(null);
  const [meta, setMeta] = useState<Meta>({ title: 'article' });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function asyncGetArticle() {
      try {
        const article = await getArticle(slug, locale);
        setMeta({ title: article.title });
        setArticle(article);
      } catch (err) {
        setError(err as string);
      }
    }

    asyncGetArticle();
  }, []);

  return article ? (
    <MetaHeader meta={meta}>
      <ArticleComponent article={article} />
    </MetaHeader>
  ) : (
    <ArticleSkeComponent />
  );
};

export default ArticlePreviewPage;
