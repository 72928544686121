import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import * as yup from 'yup';
import { useFormik } from 'formik';
import TextField from '@/components/elements/forms/textfield';
import Button from '@/components/elements/button';
import { forgetPassword } from '@/services/auth-service';
import Alert, { AlertLevel, useAlert } from '../elements/alert';
import { AuthError, getAuthErrorMessage } from '@/models/errors';
import MetaHeader from './meta';
interface ForgotPasswordProps extends RouteComponentProps {}

const ForgotPasswordPage: FunctionComponent<ForgotPasswordProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState(false);
  const { alert, triggerAlert } = useAlert();

  const meta = {
    title: 'Forgot password page',
  };

  useEffect(() => {
    if (error) {
      triggerAlert(getAuthErrorMessage(error as AuthError), AlertLevel.ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      triggerAlert('E-mail sent! check your inbox', AlertLevel.INFO);
    }
  }, [success]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async ({ email }, { setSubmitting }) => {
      setLoading(true);
      await sendResetPasswordMail(email);
      setLoading(false);
      setSubmitting(false);
    },
  });

  const sendResetPasswordMail = async (email: string) => {
    try {
      const status = await forgetPassword(email);
      if (status.status !== 'ok') {
        setError(AuthError.Unknown);
      } else {
        setSuccess(true);
      }
    } catch (err) {
      setError(err as AuthError);
    }
  };

  return (
    <MetaHeader meta={meta}>
      <Alert alert={alert} />
      <div className="container dark:text-textlightblue my-8">
        <div className="flex flex-col items-center">
          <h1 className="heading2 mb-4">Forgot password?</h1>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              placeholder=""
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <Button
              type="submit"
              appearance="dark"
              compact={true}
              button={{
                id: '0',
                text: 'Send',
                type: 'primary',
                url: '',
              }}
              disabled={formik.isSubmitting}
              loading={loading}
            />
          </form>
        </div>
      </div>
    </MetaHeader>
  );
};

export default ForgotPasswordPage;
