import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from '@reach/router';

interface UnauthorizedProps extends RouteComponentProps {}

const UnauthorizedPage: FunctionComponent<UnauthorizedProps> = () => {
  return (
    <div className="container dark:text-textlightblue my-8">
      <h1 className="heading2 mb-4">Unauthorized</h1>
      <p>you don't have the right to view this page</p>
    </div>
  );
};

export default UnauthorizedPage;
