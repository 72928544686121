import React, { FunctionComponent } from 'react';
import Moment from 'react-moment';
import Image from '@/components/image';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '@/components/elements/code-block';
import LoveButton from '@/components/elements/love-button';
import { Article } from '@/models/articles';

interface ArticleProps {
  article: Article;
}

const ArticleComponent: FunctionComponent<ArticleProps> = ({ article }) => {
  return (
    <>
      <div className="flex flex-col">
        <div>
          <Image
            style={{ maxHeight: '400px' }}
            alt={`Picture for ${article.title} article`}
            media={article.image}
            className="w-full"
            // layout="fullWidth"
          />
        </div>

        <div className="container">
          <h1 className="dark:text-white text-center heading1 my-4">
            {article.title}
          </h1>
          <h2 className="dark:text-textlightblue text-center heading2">
            {article.description}
          </h2>
          <ReactMarkdown
            className="container text-justify my-10 prose lg:prose-lg dark:prose-dark dark:lg:prose-lg-dark"
            children={article.content}
            components={{ code: CodeBlock }}
          />

          <hr className="my-4" />

          <div className="flex justify-start align-middle gap-2">
            <div>
              {article.author.picture && (
                <Image
                  media={article.author.picture}
                  alt={`Picture of ${article.author.name}`}
                  style={{ borderRadius: '50%', maxHeight: '25px' }}
                />
              )}
            </div>
            <div>
              <p className="font-medium dark:text-textlightblue">
                {article.author.name}
              </p>
              <p className="dark:text-textlightblue">
                <Moment format="MMM Do YYYY">{article.published_at}</Moment>
              </p>
            </div>
            <div className="flex-1" />
            <LoveButton slug={article.slug} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleComponent;
